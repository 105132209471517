import React from 'react';
import { graphql, Link } from 'gatsby';
import { useLocation } from '@reach/router';
import styled from 'styled-components';

import SEO from '../components/seo';
import { regroup, Imgix } from '../utils';
import usePreviewData from '../utils/usePreviewData';

const Wrapper = styled.div`
  display: block;
  width: 100%;
`;

const IndexPage = ({ data: rawData }) => {
  const data = usePreviewData(rawData);
  const params = regroup(data.prismicInstore.data);
  const location = useLocation();

  const { home } = params || {};
  const link = `${home.maxxessLink?.url}${location.search}`;

  return (
    <Wrapper>
      <SEO
        title="IPONE"
        suffix="Passion First"
      />
      <Link to="/fr/trouver-la-bonne-huile-moto/">
        <Imgix
          {...home.ipone}
        />
      </Link>
      <a
        onClick={(e) => {
          e.preventDefault();
          window.location.href = link;

          return false;
        }}
        href="#"
      >
        <Imgix
          {...home.maxxess}
        />
      </a>
    </Wrapper>
  );
};

export const query = graphql`
  query {
    prismicInstore {
      data {
        home_ipone {
          alt
          url
          dimensions {
            height
            width
          }
        }
        home_maxxess {
          alt
          dimensions {
            height
            width
          }
          url
        }
        home_maxxess_link {
          url
          target
        }
      }
    }
  }
`;

export default IndexPage;
