/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from "react";
// import PropTypes from "prop-types";

import { Helmet } from "react-helmet";

import { SiteInfos } from '../../layout';
import { truncate } from '../../utils';

export { default as ProductSnippet } from './product';
export { default as BreadcrumbSnippet } from './breadcrumb';

function SEO({
  description,
  meta,
  title,
  image,
  preconnect = [],
  preloadFonts = [],
  suffix
}) {
  const site = useContext(SiteInfos);
  const metaDescription = truncate(description || site.description, 300);
  const finalTitle = `${title || site.title} | ${suffix || 'IPONE'}`;

  const metas = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: finalTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: finalTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ];

  if (image) {
    metas.push({
      property: `og:image`,
      content: image,
    });
    metas.push({
      name: `image`,
      content: image,
    });
  }

  const jsonLD = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    'name': 'Ipone',
    'url': site.siteUrl,
    'logo': `${site.siteUrl}/assets/logo-hd.png`,
    'address': 'IPONE – Chemin La Meunière 13480 CABRIES',
    'telephone': '+33 (0)4 42 94 05 65'
  };

  return (
    <Helmet
      title={finalTitle}
      meta={metas.concat(meta).filter(m => m)}
    >
      {preconnect.map(domain => (
        <link key={domain} rel="preconnect" href={domain} />
      ))}
      {preloadFonts.map(font => (
        <link
          key={font}
          rel="preload"
          href={font}
          as="font"
          type="font/woff2"
          crossorigin="anonymous"
        />
      ))}
      <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
    </Helmet>
  );
}

// SEO.defaultProps = {
//   meta: [],
//   description: ``,
// }

// SEO.propTypes = {
//   description: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string,
// }

export default SEO
